/*
=============== 
Fonts
===============
*/

/* DX */

@font-face {
  font-family: 'DX-Rigraf';
  src: url('./assets/fonts/DX/DXRigraf-SemBdExpIta.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'DX-Rigraf';
  src: url('./assets/fonts/DX/DXRigraf-SemiBold.otf') format('opentype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'DX-Rigraf';
  src: url('./assets/fonts/DX/DXRigraf-SemiBoldItalic.otf') format('opentype');
  font-weight: 600;
  font-style: italic;
}

/* ITC */

@font-face {
  font-family: 'ITC Avant Garde Gothic Std';
  src: url('./assets/fonts/ITC/ITCAvantGardeStd-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'ITC Avant Garde Gothic Std';
  src: url('./assets/fonts/ITC/ITCAvantGardeStd-Demi.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'ITC Avant Garde Gothic Std';
  src: url('./assets/fonts/ITC/ITCAvantGardeStd-Md.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'ITC Avant Garde Gothic Std';
  src: url('./assets/fonts/ITC/ITCAvantGardeStd-Bk.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'ITC Avant Garde Gothic Std';
  src: url('./assets/fonts/ITC/ITCAvantGardeStd-DemiCnObl.ttf')
    format('truetype');
  font-weight: 600;
  font-style: italic;
}

/*
=============== 
Variables
===============
*/

:root {
  /* primary colors */
  --clr-primary-1: #8f64ab;
  --clr-primary-2: #f37053;
  --clr-primary-3: #73a2d6;
  --clr-primary-4: #00a950;
  --clr-primary-5: #ef4f96;
  --clr-primary-6: #fabf12;
  /* grey - used for custom dots */
  --clr-white: #fff;
  --clr-black: #2e2c2d;
  --clr-ivory: #ebe0d0;
  /* grey used for paragraphs */
  --ff-primary-DX: 'DX-Rigraf', sans-serif;
  --ff-primary-ITC: 'ITC Avant Garde Gothic Std', sans-serif;
  --transition: all 0.5s ease-out;
  --spacing: 0.1rem;
  --radius-100: 100px;
  --radius-50: 50px;
  --light-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.12);
  --dark-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  --max-width: 1640px;
  --max-width-laptop: 1260px;
}

/*
=============== 
Global Styles
===============
*/

*,
::after,
::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: var(--ff-primary-ITC);
  background: var(--clr-ivory);
  color: var(--clr-black);
  line-height: 1.5;
  font-size: 1.5rem;
}

main {
  overflow: hidden;
}

.wrapper {
  width: 86vw;
  max-width: var(--max-width);
  margin: 0 auto;
}

ul {
  list-style-type: none;
}

a {
  text-decoration: none;
}

button {
  cursor: pointer;
  background: transparent;
  border: 0;
  outline: 0;
  border-radius: var(--radius-100);
}

h1,
h2,
h3,
h4 {
  line-height: 1.25;
  margin-bottom: 0.25rem;
}

h1 {
  font-size: 3rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.25rem;
}

h4 {
  font-size: 0.875rem;
}

p {
  margin-bottom: 0.5rem;
  color: var(--clr-black);
}

.row {
  display: flex;
  flex-direction: row;
}

.text-center {
  margin-left: auto;
  margin-right: auto;
}

.text-right {
  text-align: right !important;
}

.align-items-center {
  align-items: center;
}

.space-between {
  justify-content: space-between;
}

.justify-center {
  justify-content: center;
}

.height-100 {
  height: 100%;
}

.width-100 {
  width: 100%;
}

@media screen and (min-width: 800px) {
  h1 {
    font-size: 4rem;
  }
  h2 {
    font-size: 2.5rem;
  }
  h3 {
    font-size: 1.75rem;
  }
  h4 {
    font-size: 1rem;
  }
  body {
    font-size: 1rem;
  }
  h1,
  h2,
  h3,
  h4 {
    line-height: 1;
  }
}

/*
=============== 
Header
===============
*/

header {
  padding: 16px 0px;
}

header .logo {
  width: 55px;
  height: 66px;
}

header .btn-contact {
  font-family: 'ITC Avant Garde Gothic Std';
  font-size: 18px;
  font-weight: 600;
  line-height: 18px;
  padding: 16px 15px 16px 24px;
  color: var(--clr-primary-1);
  border: 1px solid var(--clr-primary-1);
  display: flex;
  align-items: center;
  max-height: 52px;
  border-radius: var(--radius-100);
  cursor: pointer;
}

header .btn-contact svg {
  font-size: 35px;
}

/*
=============== 
Intro
===============
*/

.intro {
  height: 628px;
  background: var(--clr-primary-1);
  position: relative;
  /* width: 100vw; */
}

.flwr {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  height: 100%;
}

.intro .text h1 {
  font-family: 'DX-Rigraf';
  font-size: 120px;
  font-weight: 600;
  line-height: 132px;
  text-align: left;
  color: var(--clr-black);
  text-transform: uppercase;
}

.intro .text {
  z-index: 2;
}

.intro .text h1 span {
  display: block;
}

.intro .text h1 span:nth-child(2) {
  font-style: italic;
}

.intro .text h6 {
  font-family: 'ITC Avant Garde Gothic Std';
  font-size: 32px;
  font-weight: 300;
  line-height: 38.4px;
  text-align: left;
  color: var(--clr-black);
  text-transform: capitalize;
  margin-top: 32px;
}

.gif-contain {
  background-color: var(--clr-primary-2);
  width: 440px;
  height: 440px;
  border-radius: 50%;
  padding: 30px;
}

.anmate-crcl {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  transform-origin: center;
  animation-name: rotate;
  animation-duration: 15s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.anmate-crcl h4 {
  transform-origin: center;
  animation-name: antirotate;
  animation-duration: 15s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.anmate-crcl h4 span {
  font-family: 'ITC Avant Garde Gothic Std';
  font-size: 24.91px;
  font-weight: 600;
  line-height: 29.89px;
  text-align: center;
  color: var(--clr-black);
  display: block;
}

.anmate-crcl h4 span:nth-child(1) {
  font-family: 'ITC Avant Garde Gothic Std';
  font-size: 35.07px;
  font-weight: 700;
  line-height: 42.08px;
  text-align: center;
  color: var(--clr-black);
}

/*
=============== 
About US
===============
*/

.aboutus {
  height: 1874px;
  overflow: hidden;
  position: relative;
}

@keyframes rotate {
  from {
    transform: rotate(-360deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes antirotate {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(-360deg);
  }
}

.spin-circle {
  position: absolute;
  width: 2222px;
  height: 2021px;
  transform-origin: center;
  animation-name: rotate;
  animation-duration: 45s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.spin-up {
  /* top: 0;
  right: 0; */

  /* top: -80%;
  right: -83%;
  transform: scale(0.5); */
  /* 
  top: -50%;
  right: -51%;
  transform: rotate(-141.06deg); */

  top: -50%;
  transform: rotate(-140.06deg);
  left: 39%;
}

.spin-dwn {
  /* bottom: 0;
  left: 0; */

  bottom: -29%;
  transform: rotate(-129.06deg);
  right: 52%;
}

.top-section {
  margin: 80px auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 2;
  position: relative;
  max-width: 1920px;
}

.top-section .boomLogo {
  transition: all 0.3s ease-out;
}

.top-section .boomLogo:hover {
  transform: scale(1.12);
}

.top-section p {
  color: var(--clr-black);
  font-family: 'ITC Avant Garde Gothic Std';
  font-size: 24px;
  font-weight: 300;
  line-height: 28.8px;
  text-align: center;
  margin-bottom: 80px;
}

.games {
  max-width: 1059px;
  width: 55%;
  margin: 0 auto 60px;
}

.games .game {
  height: 168px;
  min-width: 168px;
  border-radius: 50%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.game svg,
.stage svg {
  position: absolute;
  top: 0;
  left: 0;
  transition: var(--transition);
}

.game h6,
.stage h6 {
  color: var(--clr-ivory);
  font-family: 'ITC Avant Garde Gothic Std';
  font-size: 32px;
  font-weight: 600;
  line-height: 38.4px;
  text-align: center;
  z-index: 2;
  position: relative;
  transition: var(--transition);
}

.game.bowling h6 {
  margin-left: 15px;
}

.game.bowling:hover svg path {
  fill: var(--clr-primary-4);
}
.game.bowling:hover svg,
.game.arcade:hover svg,
.game.darts:hover svg {
  transform: rotate(45deg);
}
.game:hover h6 {
  color: var(--clr-black);
}

.game.arcade:hover svg path {
  fill: var(--clr-primary-1);
}

.game.golf:hover svg path {
  fill: var(--clr-primary-3);
}
.game.golf:hover svg {
  transform: scale(1.05);
}

.game.darts:hover svg path {
  fill: var(--clr-primary-5);
}

.top-section h5 {
  font-family: 'ITC Avant Garde Gothic Std';
  font-size: 32px;
  font-weight: 600;
  line-height: 38.4px;
  text-align: center;
  color: #000000;
  margin-bottom: 40px;
}

.sports {
  gap: 32px;
}

.sports h4 {
  font-family: 'ITC Avant Garde Gothic Std';
  font-size: 32px;
  font-weight: 600;
  line-height: 38.4px;
  text-align: center;
  color: var(--clr-black);
}

.sports h3 {
  font-family: 'DX-Rigraf';
  font-size: 51.98px;
  font-weight: 600;
  line-height: 57.17px;
  text-align: center;
  color: var(--clr-black);
}

.btm-section {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  grid-template-rows: 718px;
  place-items: center;
  max-width: 1920px;
  margin: 0 auto;
}
/* 
.framePIC {
  transition: all 0.5s ease-out;
  z-index: 3;
} */

.main-container {
  /* width: 605px; */
  width: 624px;
  height: 743px;
  /* overflow: hidden; */
  position: relative;
}

.avatar-container.c2 {
  background-color: transparent;
  z-index: 1;
}

.main-img {
  /* width: 605px; */
  width: 555px;
  position: relative;
  top: 2px;
  right: -36px;
  /* right: -27px; */
  z-index: 2;
  transition: all 0.3s ease-out;
}

.main-container:hover .main-img {
  /* transform: scale(1.06); */
  transform: translate(-8px, -30px);
}

.main-container:hover .circles {
  /* transform: scale(1.06); */
  visibility: visible;
  /* transition: all 0.3s ease-out; */
  opacity: 1;
}

.main-container:hover .contained-img {
  /* transform: scale(1.06); */
  transform: translate(-8px, -30px) !important;
}

.contained-img {
  /* width: 560px; */
  width: 557px;
  position: relative;
  top: -11rem;
  right: -3px;
  transition: all 0.3s ease-out;
}

.circles {
  position: absolute;
  transition: all 0.3s ease-out;
  opacity: 0;
  /* 
  left: -9px;
  top: -45px; */
  left: 0px;
  top: -119px;
  width: 640px;
  visibility: hidden;
}

.avatar-container {
  background: linear-gradient(#591735, #b53874, #ff8fbf);
  height: 501px;
  width: 554px;
  overflow: hidden;
  z-index: -1;
  position: absolute;
  top: 11rem;
  /* top: 18rem;
  right: 5rem; */
  /* top: 9rem; */
  left: 2rem;
  clip-path: polygon(
    50.087% 100%,
    50.087% 100%,
    48.917% 99.762%,
    47.796% 99.111%,
    46.715% 98.143%,
    45.664% 96.955%,
    44.631% 95.643%,
    43.608% 94.302%,
    42.583% 93.028%,
    41.547% 91.919%,
    40.49% 91.069%,
    39.4% 90.575%,
    39.4% 90.575%,
    38.175% 90.475%,
    36.836% 90.708%,
    35.415% 91.184%,
    33.944% 91.813%,
    32.455% 92.505%,
    30.979% 93.17%,
    29.549% 93.718%,
    28.197% 94.059%,
    26.954% 94.103%,
    25.852% 93.76%,
    25.852% 93.76%,
    24.931% 92.98%,
    24.233% 91.857%,
    23.706% 90.472%,
    23.301% 88.903%,
    22.966% 87.231%,
    22.649% 85.535%,
    22.301% 83.894%,
    21.869% 82.389%,
    21.304% 81.098%,
    20.553% 80.102%,
    20.553% 80.102%,
    19.529% 79.398%,
    18.252% 78.922%,
    16.793% 78.611%,
    15.22% 78.404%,
    13.603% 78.238%,
    12.011% 78.053%,
    10.512% 77.785%,
    9.176% 77.373%,
    8.071% 76.755%,
    7.268% 75.869%,
    7.268% 75.869%,
    6.841% 74.722%,
    6.769% 73.396%,
    6.971% 71.933%,
    7.365% 70.373%,
    7.87% 68.756%,
    8.405% 67.123%,
    8.888% 65.516%,
    9.238% 63.975%,
    9.373% 62.54%,
    9.212% 61.252%,
    9.212% 61.252%,
    8.692% 60.106%,
    7.846% 59.003%,
    6.764% 57.93%,
    5.536% 56.874%,
    4.251% 55.821%,
    2.999% 54.758%,
    1.868% 53.671%,
    0.95% 52.547%,
    0.332% 51.372%,
    0.105% 50.133%,
    0.105% 50.133%,
    0.331% 48.893%,
    0.947% 47.716%,
    1.864% 46.589%,
    2.994% 45.5%,
    4.245% 44.434%,
    5.529% 43.379%,
    6.755% 42.321%,
    7.835% 41.247%,
    8.679% 40.144%,
    9.196% 38.997%,
    9.196% 38.997%,
    9.355% 37.71%,
    9.218% 36.275%,
    8.866% 34.734%,
    8.381% 33.127%,
    7.844% 31.496%,
    7.337% 29.879%,
    6.941% 28.319%,
    6.738% 26.855%,
    6.809% 25.529%,
    7.236% 24.38%,
    7.236% 24.38%,
    8.038% 23.492%,
    9.141% 22.872%,
    10.477% 22.457%,
    11.976% 22.187%,
    13.568% 21.999%,
    15.186% 21.831%,
    16.758% 21.621%,
    18.217% 21.308%,
    19.492% 20.829%,
    20.516% 20.123%,
    20.516% 20.123%,
    21.264% 19.126%,
    21.827% 17.834%,
    22.256% 16.328%,
    22.602% 14.686%,
    22.915% 12.989%,
    23.248% 11.316%,
    23.651% 9.746%,
    24.175% 8.36%,
    24.872% 7.236%,
    25.793% 6.454%,
    25.793% 6.454%,
    26.893% 6.11%,
    28.136% 6.153%,
    29.489% 6.493%,
    30.92% 7.039%,
    32.396% 7.702%,
    33.886% 8.392%,
    35.358% 9.018%,
    36.779% 9.492%,
    38.117% 9.723%,
    39.341% 9.621%,
    39.341% 9.621%,
    40.43% 9.125%,
    41.487% 8.273%,
    42.522% 7.162%,
    43.545% 5.887%,
    44.566% 4.544%,
    45.597% 3.23%,
    46.646% 2.04%,
    47.725% 1.071%,
    48.844% 0.418%,
    50.012% 0.178%,
    50.012% 0.178%,
    51.183% 0.417%,
    52.304% 1.068%,
    53.385% 2.036%,
    54.436% 3.225%,
    55.469% 4.538%,
    56.492% 5.879%,
    57.517% 7.152%,
    58.553% 8.261%,
    59.611% 9.111%,
    60.7% 9.603%,
    60.7% 9.603%,
    61.925% 9.703%,
    63.264% 9.47%,
    64.684% 8.994%,
    66.155% 8.365%,
    67.645% 7.672%,
    69.12% 7.007%,
    70.551% 6.458%,
    71.903% 6.116%,
    73.146% 6.071%,
    74.248% 6.412%,
    74.248% 6.412%,
    75.169% 7.191%,
    75.867% 8.313%,
    76.394% 9.698%,
    76.799% 11.267%,
    77.134% 12.939%,
    77.451% 14.636%,
    77.799% 16.277%,
    78.231% 17.783%,
    78.796% 19.074%,
    79.546% 20.07%,
    79.546% 20.07%,
    80.57% 20.774%,
    81.845% 21.25%,
    83.304% 21.561%,
    84.877% 21.768%,
    86.495% 21.934%,
    88.088% 22.119%,
    89.587% 22.387%,
    90.924% 22.799%,
    92.029% 23.417%,
    92.832% 24.303%,
    92.832% 24.303%,
    93.259% 25.45%,
    93.331% 26.776%,
    93.129% 28.239%,
    92.735% 29.8%,
    92.229% 31.416%,
    91.695% 33.049%,
    91.212% 34.656%,
    90.862% 36.198%,
    90.727% 37.633%,
    90.887% 38.92%,
    90.887% 38.92%,
    91.408% 40.068%,
    92.254% 41.171%,
    93.337% 42.244%,
    94.566% 43.301%,
    95.852% 44.354%,
    97.105% 45.417%,
    98.236% 46.504%,
    99.155% 47.629%,
    99.773% 48.804%,
    100% 50.045%,
    100% 50.045%,
    99.775% 51.285%,
    99.158% 52.462%,
    98.241% 53.588%,
    97.111% 54.676%,
    95.859% 55.741%,
    94.575% 56.796%,
    93.348% 57.854%,
    92.267% 58.928%,
    91.422% 60.033%,
    90.904% 61.181%,
    90.904% 61.181%,
    90.745% 62.468%,
    90.882% 63.903%,
    91.234% 65.444%,
    91.719% 67.05%,
    92.256% 68.682%,
    92.764% 70.297%,
    93.161% 71.857%,
    93.365% 73.32%,
    93.295% 74.645%,
    92.87% 75.792%,
    92.87% 75.792%,
    92.067% 76.68%,
    90.964% 77.3%,
    89.628% 77.715%,
    88.129% 77.985%,
    86.537% 78.173%,
    84.92% 78.341%,
    83.347% 78.551%,
    81.888% 78.864%,
    80.613% 79.343%,
    79.589% 80.049%,
    79.589% 80.049%,
    78.841% 81.046%,
    78.277% 82.338%,
    77.848% 83.845%,
    77.502% 85.486%,
    77.188% 87.183%,
    76.855% 88.856%,
    76.452% 90.426%,
    75.928% 91.813%,
    75.232% 92.937%,
    74.312% 93.719%,
    74.312% 93.719%,
    73.213% 94.062%,
    71.971% 94.019%,
    70.618% 93.679%,
    69.188% 93.133%,
    67.711% 92.47%,
    66.221% 91.78%,
    64.748% 91.154%,
    63.327% 90.68%,
    61.988% 90.449%,
    60.764% 90.551%,
    60.764% 90.551%,
    59.675% 91.047%,
    58.618% 91.899%,
    57.583% 93.01%,
    56.56% 94.285%,
    55.539% 95.628%,
    54.509% 96.942%,
    53.459% 98.132%,
    52.38% 99.101%,
    51.262% 99.754%,
    50.093% 99.994%,
    50.087% 100%
  );
}

.btm-section .stage {
  position: relative;
  height: 193px;
  width: 333px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin-left: 10%; */
}

.btm-section .stage:hover svg path {
  fill: var(--clr-primary-2);
}

.btm-section .stage:hover svg {
  transform: skewX(-18deg);
}

.btm-section ul {
  padding-left: 50px;
  z-index: 3;
}

.btm-section .actions li {
  font-family: 'ITC Avant Garde Gothic Std';
  font-size: 32px;
  font-weight: 600;
  line-height: 38.4px;
  color: var(--clr-black);
  margin-bottom: 40px;
}

/*
=============== 
Rooms
===============
*/

.rooms {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  /* grid-template-rows: 382.2px; */
  /* grid-template-rows: 400.9px; */
  place-items: center;
  padding-bottom: 18.7px;
  margin-top: -10%;
  margin-bottom: 100px;

  max-width: 1920px;
  margin-left: auto;
  margin-right: auto;
}

.rooms .room {
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  height: 100%;
  aspect-ratio: 1;
}

.rooms .img-within:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 60%;
  z-index: 2;
  mix-blend-mode: multiply;
  background: var(--clr-primary-5);
}

.rooms .img-within:nth-child(1):before {
  background: var(--clr-primary-2);
}

.rooms .img-within img {
  width: 100%;
  padding: 0;
  margin: 0;
  display: block;
  transition: all 0.3s ease-in-out;
}

.rooms .img-within:hover img {
  transform: scale(1.2);
}

.rooms .img-within h4 {
  visibility: hidden;
  font-family: 'DX-Rigraf';
  font-size: 40px;
  font-style: italic;
  font-weight: 600;
  line-height: 44px;
  color: var(--clr-ivory);
  transition: all 0.3s ease-out;
  z-index: 3;
  position: absolute;
  top: 45%;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
}

.rooms .room.bg {
  background: var(--clr-primary-1);
  transition: all 0.3s ease-out;
}

.rooms .room.bg:hover {
  background: var(--clr-primary-2);
}

.rooms h3 {
  position: absolute;
  /* top: 21%; */
  top: 24%;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
  transition: all 0.2s ease-in-out;
  font-family: 'DX-Rigraf';
  /* font-size: 66px;
  font-weight: 600;
  line-height: 72.6px; */
  font-size: 58px;
  font-weight: 600;
  line-height: 64.6px;
}

.rooms .room.bg:hover h3 {
  /* font-size: 70px;
  line-height: 76px; */

  font-size: 62px;
  line-height: 68px;

  /* font-size: 64px; */
  /* font-weight: 500; */
  /* top: 30%; */
  /* left: -95px; */
  /* transform: translate(-40px, 0); */
}

.rooms h3 span {
  display: block;
  margin: 17px 0px;
}

.rooms .room.bg.sm h3 {
  font-family: 'ITC Avant Garde Gothic Std';
  /* font-size: 32.58px;
  font-weight: 500;
  line-height: 39.1px; */
  font-size: 29.58px;
  font-weight: 500;
  line-height: 33.1px;
}

.rooms .room.bg.sm:hover h3 {
  font-size: 30.58px;
  line-height: 34.1px;
  color: var(--clr-ivory);
}

.rooms .img-within:hover h4 {
  visibility: visible;
}

/*
=============== 
Highlight Attractions
===============
*/

.attractions {
  display: block;
}

.attractions h1 {
  color: var(--clr-black);
  font-family: 'DX-Rigraf';
  font-size: 64px;
  font-weight: 600;
  line-height: 70.4px;
  text-align: center;
}

.highlights {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
}

@keyframes fade-in-move-right {
  0% {
    display: block;
    opacity: 0;
    z-index: 3;
    transform: translateX(-4rem);
  }
  30% {
    display: block;
    opacity: 0;
    z-index: 3;
    transform: translateX(-3rem);
  }
  100% {
    display: block;
    opacity: 1;
    z-index: 3;
    transform: translateX(0);
  }
}

.single-highlight {
  height: 732px;
  /* padding: 10px; */
  transition: var(--transition);
  flex-grow: 1;
  flex-basis: 0;
  position: relative;
  transform-origin: center;
  padding: 61px 36px;

  /* background-position: center !important; */
  /* background-size: 100% 100% !important; */

  /* background-position: center top !important;
  background-repeat: no-repeat !important;
  background-size: auto !important; */

  display: flex;
  align-items: center;
  justify-content: center;

  overflow: hidden;
}

.single-highlight .highlight-img {
  /* height: 140%; */
  height: calc(100vw - 150%);
  position: absolute;
  object-fit: cover;
  object-position: center;
  top: -26px;
}

.single-highlight:hover {
  flex-grow: 1.7;
  flex-basis: 0;
  /* height: 732px; */
  transform-origin: center;
  transition: var(--transition);
}

.single-highlight:hover h2 {
  display: none;
}

.single-highlight:hover .highlight-content {
  /* display: block; */
  visibility: visible;
  opacity: 1;
  animation: fade-in-move-right 0.7s;
  transform: translateX(0);
}

.single-highlight:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 80%;
  z-index: 2;
  mix-blend-mode: multiply;
  background: var(--clr-primary-5);
}

.single-highlight:nth-child(3):before {
  background: var(--clr-primary-1);
}

.single-highlight h2 {
  color: var(--clr-ivory);
  font-family: 'DX-Rigraf';
  font-size: 50px;
  font-weight: 600;
  line-height: 55px;
  text-align: center;
  position: relative;
  z-index: 3;
  display: block;
}

.highlight-content {
  background: #0000004d;
  border-radius: 20px;
  padding: 20px;
  /* display: none; */
  height: calc(100% - 122px);
  transform-origin: top right;

  position: absolute;
  visibility: hidden;
  opacity: 0;
  top: 61px;
  left: 36px;
  /* width: 85%; */
  width: calc(100vw / 4.2);
  transform: translateX(-4rem);
  z-index: 3;
}

.highlight-content .title {
  font-family: 'ITC Avant Garde Gothic Std';
  font-size: 32px;
  font-weight: 600;
  line-height: 38.4px;
  text-align: left;
  color: var(--clr-ivory);
  position: relative;
  z-index: 3;
}

.highlight-content p {
  font-family: 'ITC Avant Garde Gothic Std';
  font-size: 24px;
  font-weight: 300;
  line-height: 28.8px;
  text-align: left;
  color: var(--clr-ivory);
  position: relative;
  z-index: 3;
}

.highlight-content .btn-readMore {
  font-family: 'DX-Rigraf';
  font-size: 21.08px;
  font-weight: 600;
  line-height: 25.3px;
  padding: 18.74px 28.11px;
  /* padding: 16px 15px 16px 24px; */
  color: var(--clr-white);
  border: 1px solid var(--clr-white);
  display: flex;
  align-items: center;
  max-height: 62px;
  position: relative;
  z-index: 3;
  margin-top: 20px;
}

.highlight-content .btn-readMore svg {
  font-size: 38px;
}

/*
=============== 
Slogan+
===============
*/

@keyframes slide {
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(-2179px, 0, 0);
  }
}

@keyframes slideMobile {
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(-470px, 0, 0);
  }
}

@keyframes slideTablet {
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(-1437px, 0, 0);
  }
}

.slogan-section {
  overflow: hidden;
  margin: 60px 0px;
}

.sliding-background {
  animation: slide 20s linear infinite;
}

/*
=============== 
F&B
===============
*/

.fnb {
  width: 87.5%;
  max-width: 1681px;
  margin: 0 auto 100px;
  gap: 40px;
}

.fnb .text h3 {
  font-family: 'DX-Rigraf';
  font-size: 64px;
  font-weight: 600;
  line-height: 70.4px;
  text-align: left;
  color: var(--clr-black);
}

.fnb .text p {
  font-family: 'ITC Avant Garde Gothic Std';
  font-size: 32px;
  font-weight: 600;
  line-height: 38.4px;
  text-align: left;
  color: var(--clr-black);
}

.fnb .img-wrap img {
  transition: all 0.3s ease-out;
}

.fnb .img-wrap:hover img {
  transform: scale(1.05);
}

/*
=============== 
Contact Us
===============
*/

.contact-us {
  overflow: hidden;
  margin: 0px;
  background-color: var(--clr-primary-1);
  height: 635px;
}

.contact-us-wrap {
  flex-direction: column;
}

.contact-form {
  width: 1185px;
}

.contact-form .text h3 {
  font-family: 'DX-Rigraf';
  font-size: 100px;
  font-weight: 600;
  line-height: 110px;
  text-align: left;
  color: var(--clr-black);
  text-transform: uppercase;
}

.contact-form .text h3 span {
  font-style: italic;
  display: block;
}

.contact-form form {
  width: 42%;
}

.contact-form .form-control {
  width: 100%;
  padding: 27px 0px;
  border-color: transparent;
  border-bottom: 3.66px solid var(--clr-black);
  font-size: 15px;
  background: transparent;

  font-family: 'ITC Avant Garde Gothic Std';
  font-size: 29px;
  font-weight: 300;
  line-height: 31px;
  /* text-transform: capitalize; */
}

.contact-form input:focus {
  outline: none;
}

.contact-form input::-webkit-input-placeholder {
  padding-left: 1px;
  font-size: 29px;
  color: var(--clr-black);
}

.contact-form input:-moz-placeholder {
  /* Firefox 18- */
  padding-left: 1px;
  font-size: 29px;
  color: var(--clr-black);
}

.contact-form input::-moz-placeholder {
  /* Firefox 19+ */
  padding-left: 1px;
  font-size: 29px;
  color: var(--clr-black);
}

.contact-form input:-ms-input-placeholder {
  padding-left: 1px;
  font-size: 29px;
  color: var(--clr-black);
}

.contact-us .btn-submit {
  font-family: 'ITC Avant Garde Gothic Std';
  font-size: 28px;
  font-style: italic;
  font-weight: 600;
  line-height: 33.6px;

  padding: 24px 80px;
  color: var(--clr-ivory);
  background: var(--clr-black);
  display: flex;
  align-items: center;
  max-height: 82px;
  transition: all 0.3s ease-out;
  letter-spacing: 1px;
}

.contact-us .btn-submit svg {
  font-size: 48px;
  margin-top: -3px;
}

.contact-us .btn-submit:hover {
  background: var(--clr-ivory);
  color: var(--clr-black);
  transform-origin: top left;
  transform: scale(1.05);
}

/*
=============== 
Footer
===============
*/

footer {
  background: var(--clr-black);
  padding: 20px;
}

.footer-top {
  width: 95%;
  max-width: 1640px;
  margin-top: 80px;
}

.col {
  display: flex;
  flex-direction: column;
}

.footerIcons {
  display: flex;
  justify-content: center;
  line-height: 1;
  margin-bottom: 0.5rem;
}
.footerIcons a {
  font-size: 40px;
  margin: 0 1.25rem;
  transition: var(--transition);
  color: var(--clr-white);
}
.footerIcons li {
  transition: var(--transition);
}
.footerIcons li:hover {
  transform: translateY(-10%);
}

.footer-text h3 {
  font-family: 'DX-Rigraf';
  font-size: 62px;
  font-style: italic;
  font-weight: 600;
  line-height: 62px;
  color: var(--clr-ivory);
  text-transform: uppercase;
}

.footer-text h6 {
  font-family: 'ITC Avant Garde Gothic Std';
  font-size: 18.76px;
  font-weight: 500;
  line-height: 27.8px;
  letter-spacing: 0.03em;
  color: var(--clr-ivory);
  text-transform: uppercase;
}

.footer-text h6 span {
  font-weight: 600;
}

footer .copyright {
  color: var(--clr-ivory);
  font-family: 'ITC Avant Garde Gothic Std';
  font-size: 18px;
  font-weight: 300;
  line-height: 26.67px;
  text-align: center;
  margin: 80px auto;
}

.footer-text {
  margin-right: 120px;
}

/*
=============== 
Media Queries
===============
*/

@media screen and (max-width: 2400px) {
  .single-highlight .highlight-img {
    height: calc(100vw - 120%);
  }
}

@media screen and (max-width: 1900px) {
  .single-highlight .highlight-img {
    height: 140%;
  }
}

@media screen and (max-width: 1520px) {
  .intro .text h1 {
    font-size: 85px;
    line-height: 80px;
  }
  .intro .text h6 {
    font-size: 20px;
    margin-top: 12px;
  }
  .gif-contain {
    width: 365px;
    height: 365px;
  }
  .anmate-crcl h4 span:nth-child(1) {
    font-size: 27.07px;
  }
  .anmate-crcl h4 span {
    font-size: 14.91px;
    line-height: 20.89px;
  }
  .intro {
    height: 560px;
  }
  .top-section .boomLogo {
    transform: scale(0.8);
  }
  .top-section {
    margin: 50px auto;
  }
  .top-section p {
    font-size: 18px;
    line-height: 23.8px;
    margin-bottom: 60px;
  }
  .games {
    width: 65%;
  }
  .games .game {
    height: 155px;
    min-width: 155px;
  }
  .game svg,
  .stage svg {
    height: 100%;
  }
  .game h6,
  .stage h6 {
    font-size: 24px;
    line-height: 27.4px;
    margin-left: 8px;
  }
  .game.bowling h6 {
    margin-left: 25px;
  }
  .top-section h5 {
    margin-bottom: 20px;
    font-size: 26px;
  }
  .sports h4 {
    font-size: 26px;
  }
  .sports h3 {
    font-size: 42.98px;
    line-height: 47.17px;
  }
  /* .framePIC {
    max-width: 420px;
  } */
  .btm-section .actions li {
    font-size: 23px;
    line-height: 33.4px;
    margin-bottom: 13px;
  }
  .btm-section ul {
    padding-left: 35px;
  }
  .btm-section .stage {
    height: 155px;
    width: 295px;
  }
  .stage h6 {
    font-size: 19px;
    line-height: 25.4px;
    margin-left: 35px;
  }
  .aboutus {
    height: 1630px;
  }

  /*  */

  .main-container {
    width: 424px;
    height: 543px;
  }
  .main-img {
    width: 355px;
    top: 15px;
    right: -37px;
  }

  .avatar-container {
    height: 320px;
    width: 354px;
    top: 8rem;
  }

  .contained-img {
    width: 357px;
    top: -116px;
    right: -3px;
  }

  .circles {
    width: 450px;
    /* left: -24px; */
    left: -7px;
    top: -77px;
  }

  /*  */
  .rooms .img-within h4 {
    font-size: 30px;
  }
  .rooms h3 {
    font-size: 48px;
    line-height: 50.6px;
    top: 25%;
  }

  .rooms .room.bg:hover h3 {
    font-size: 52px; /* +4 */
    /* transform: translate(-30px, 0); */
    line-height: 54.6px;
  }
  .rooms .room.bg.sm h3 {
    font-size: 26px;
    line-height: 31.1px;
    top: 19%;
  }
  .rooms .room.bg.sm:hover h3 {
    font-size: 27px; /* +1 */
    line-height: 32.1px;
    /* transform: translate(-22px, 0); */
  }
  .rooms {
    margin-bottom: 70px;
  }
  .attractions h1 {
    font-size: 54px;
  }
  .single-highlight {
    height: 560px;
  }
  .highlight-content {
    left: 29px;
  }
  .single-highlight h2 {
    font-size: 35px;
    line-height: 40px;
  }
  .highlight-content .title {
    font-size: 24px;
    line-height: 33.4px;
  }
  .highlight-content p {
    font-size: 18px;
    line-height: 26.8px;
  }
  .highlight-content .btn-readMore {
    font-size: 15.08px;
    font-weight: 500;
    padding: 4.74px 21.11px;
  }
  .highlight-content .btn-readMore svg {
    font-size: 34px;
  }
  .slogan-section {
    margin: 50px 0px;
  }
  .fnb .text h3 {
    font-size: 50px;
    line-height: 57.4px;
  }
  .fnb .text p {
    font-size: 21px;
    line-height: 32.4px;
  }
  .fnb .img-wrap img {
    width: 523px;
    height: 398px;
  }
  .fnb {
    margin: 0 auto 50px;
  }
  .contact-form .text h3 {
    font-size: 75px;
    line-height: 85px;
  }
  .contact-form .form-control {
    padding: 18px 0px;
    border-bottom: 1.66px solid var(--clr-black);
    font-size: 20px;
    line-height: 26px;
  }

  .contact-form input::-webkit-input-placeholder {
    font-size: 20px;
  }

  .contact-form input:-moz-placeholder {
    /* Firefox 18- */
    font-size: 20px;
  }

  .contact-form input::-moz-placeholder {
    /* Firefox 19+ */
    font-size: 20px;
  }

  .contact-form input:-ms-input-placeholder {
    font-size: 20px;
  }
  .contact-form {
    justify-content: space-evenly !important;
  }
  .contact-us .btn-submit {
    font-size: 20px;
    padding: 10px 45px;
  }
  .contact-form form {
    width: 33%;
  }
  .contact-us {
    height: 500px;
  }
  .footer-top {
    margin-top: 55px;
  }
  .footer-text {
    margin-right: 80px;
  }
  .footer-text h3 {
    font-size: 52px;
    line-height: 52px;
  }
  .footer-text h6 {
    font-size: 13.76px;
  }
  footer .copyright {
    font-size: 14px;
    margin: 46px auto;
  }
}

@media screen and (max-width: 1395px) {
  .rooms h3 {
    font-size: 40px;
    line-height: 40.6px;
    top: 28%;
  }
  .rooms .room.bg:hover h3 {
    /* font-size: 40px; */
    font-size: 44px;
    line-height: 44.6px;
  }
  .rooms .room.bg.sm h3 {
    font-size: 21.58px;
    line-height: 25.1px;
  }
  .rooms .room.bg.sm:hover h3 {
    /* font-size: 21.58px;
    transform: translate(-12px, 0); */
    font-size: 22.58px;
    line-height: 26.1px;
  }
  .highlight-content {
    left: 25px;
    top: 35px;
    height: calc(100% - 77px);
  }
  .single-highlight {
    height: 515px;
  }
}

@media screen and (max-width: 1250px) {
  .rooms .img-within h4 {
    font-size: 25px;
    line-height: 32px;
  }

  /*  */

  .main-container {
    width: 324px;
    height: 443px;
  }
  .main-img {
    width: 255px;
    top: 30px;
  }

  .avatar-container {
    height: 230px;
    width: 254px;
    top: 7rem;
  }

  .main-container:hover .main-img {
    transform: translate(-5px, -10px);
  }

  .main-container:hover .contained-img {
    transform: translate(-5px, -10px) !important;
  }

  .contained-img {
    width: 257px;
    top: -85px;
  }

  .circles {
    width: 320px;
    /* left: -8px; */
    left: 7px;
    top: -28px;
  }

  /*  */
}

@media screen and (max-width: 1132px) {
  .intro .text h1 {
    font-size: 60px;
    line-height: 60px;
  }
  .intro .text h6 {
    font-size: 14px;
    margin-top: 3px;
  }
  .gif-contain {
    width: 330px;
    height: 330px;
  }
  .anmate-crcl h4 span:nth-child(1) {
    font-size: 20.07px;
  }
  .anmate-crcl h4 span {
    font-size: 10.91px;
    line-height: 15.89px;
  }
  .intro {
    height: 450px;
  }
  header .btn-contact {
    font-size: 13px;
  }
  .top-section .boomLogo {
    transform: scale(0.6);
  }
  .top-section {
    margin: 15px auto;
  }
  .top-section .boomLogo:hover {
    transform: scale(0.8);
  }
  .top-section p {
    font-size: 16px;
    line-height: 22.8px;
    margin-bottom: 41px;
  }
  .games .game {
    height: 120px;
    min-width: 120px;
  }
  .game h6,
  .stage h6 {
    font-size: 17px;
    line-height: 21.4px;
    margin-left: 45px;
  }
  .game.bowling h6 {
    margin-left: 59px;
  }
  .games {
    margin: 0 auto 35px;
  }
  .sports {
    gap: 20px;
  }
  .top-section h5 {
    margin-bottom: 5px;
    font-size: 18px;
  }
  .sports h4 {
    font-size: 18px;
  }
  .sports h3 {
    font-size: 27.98px;
    line-height: 43.17px;
  }
  .framePIC {
    max-width: 320px;
  }
  .btm-section .actions li {
    font-size: 17px;
    line-height: 25.4px;
    margin-bottom: 10px;
  }
  .btm-section .stage {
    height: 115px;
    width: 255px;
  }
  .stage h6 {
    font-size: 13px;
    line-height: 15.4px;
    margin-left: 73px;
  }
  .aboutus {
    height: 1150px;
  }
  .btm-section {
    grid-template-rows: 475px;
  }
  .spin-up {
    top: -39%;
    transform: rotate(-185.06deg);
    left: 37%;
  }
  .spin-circle {
    width: 1322px;
    height: 1121px;
  }
  .spin-dwn {
    bottom: -22%;
    transform: rotate(-153.06deg);
    right: 47%;
  }
  .rooms .img-within h4 {
    font-size: 20px;
  }
  .rooms h3 {
    font-size: 31px;
    line-height: 33.6px;
  }
  .rooms .room.bg:hover h3 {
    /* font-size: 30px; */
    font-size: 35px;
    line-height: 37.6px;
  }
  .rooms .room.bg.sm h3 {
    font-size: 17.58px;
    line-height: 21.1px;
  }
  .rooms .room.bg.sm:hover h3 {
    /* font-size: 17.58px; */
    font-size: 18.58px;
    line-height: 22.1px;
  }
  .rooms h3 span {
    margin: 10px 0px;
  }

  .rooms .img-within h4 {
    line-height: 25px;
  }
  .rooms {
    margin-bottom: 30px;
  }
  .attractions h1 {
    font-size: 35px;
  }
  .single-highlight {
    height: 375px;
  }
  .single-highlight .highlight-img {
    height: 155%;
  }
  .single-highlight h2 {
    font-size: 23px;
    line-height: 28px;
  }
  .highlight-content .title {
    font-size: 16px;
    line-height: 24.4px;
  }
  .highlight-content p {
    font-size: 13px;
    line-height: 23.8px;
  }
  .highlight-content .btn-readMore {
    font-size: 11.08px;
    font-weight: 600;
    padding: 0.74px 14.11px;
  }
  .highlight-content {
    width: calc(100vw / 4.5);
  }
  .fnb .text h3 {
    font-size: 30px;
    line-height: 40.4px;
  }
  .fnb .text p {
    font-size: 14px;
    line-height: 23.4px;
  }
  .fnb .img-wrap img {
    width: 403px;
    height: 278px;
  }
  .contact-form .text h3 {
    font-size: 50px;
    line-height: 60px;
  }
  .contact-form .form-control {
    padding: 12px 0px;
    border-bottom: 1px solid var(--clr-black);
    font-size: 18px;
    line-height: 20px;
  }
  .contact-us .btn-submit {
    font-size: 16px;
    padding: 5px 20px;
  }
  .contact-us .btn-submit svg {
    font-size: 32px;
    margin-top: -3px;
  }

  .contact-form input::-webkit-input-placeholder {
    font-size: 17px;
  }

  .contact-form input:-moz-placeholder {
    /* Firefox 18- */
    font-size: 17px;
  }

  .contact-form input::-moz-placeholder {
    /* Firefox 19+ */
    font-size: 17px;
  }

  .contact-form input:-ms-input-placeholder {
    font-size: 17px;
  }
  .contact-form form {
    width: 25%;
  }
  .contact-us {
    height: 375px;
  }
  .footer-top {
    margin-top: 35px;
  }
  .footer-logo {
    width: 200px;
  }
  .footer-logo img {
    width: 100%;
  }
  .footer-text h3 {
    font-size: 35px;
    line-height: 35px;
  }
  .footer-text h6 {
    font-size: 10.76px;
  }
  footer .copyright {
    font-size: 11px;
    margin: 25px auto;
  }
}

@media screen and (max-width: 960px) {
  header .logo {
    width: 34px;
    height: 45px;
  }
  header .btn-contact {
    font-size: 10px;
    padding: 2px 15px 2px 24px;
  }
  header .btn-contact svg {
    font-size: 30px;
  }
  .intro .text h1 {
    font-size: 50px;
    line-height: 50px;
  }
  .gif-contain {
    width: 270px;
    height: 270px;
  }
  .intro {
    height: 370px;
  }
  .intro .text h6 {
    font-size: 13.8px;
    /* margin-top: -5px; */
  }
  .flwr {
    top: 20%;
    height: 65%;
  }
  .anmate-crcl h4 span:nth-child(1) {
    font-size: 16.07px;
    line-height: 30.08px;
  }
  .games {
    width: 67%;
  }
  .game svg {
    left: unset;
    /* right: 0; */
  }
  .game h6 {
    margin-left: 0;
  }
  .games .game {
    height: 100px;
    min-width: 100px;
  }
  .game.bowling h6 {
    margin-left: 0px;
  }
  .game h6,
  .stage h6 {
    font-size: 14px;
    line-height: 18.4px;
  }
  .top-section h5 {
    margin-bottom: 0px;
    font-size: 15px;
  }
  .sports h3 {
    font-size: 21.98px;
    line-height: 43.17px;
  }
  .sports h4 {
    font-size: 14px;
  }
  .sports {
    gap: 14px;
  }
  /* .framePIC {
    max-width: 250px;
  } */

  /*  */

  .main-container {
    width: 124px;
    height: 243px;
    margin-right: 40px;
  }
  .main-img {
    width: 161px;
    top: 12px;
    right: -32px;
  }

  .avatar-container {
    height: 150px;
    width: 157px;
    top: 3rem;
  }

  /* .main-container:hover .main-img {
    transform: translate(-5px, -10px);
  }

  .main-container:hover .contained-img {
    transform: translate(-5px, -10px) !important;
  } */

  .contained-img {
    width: 161px;
    top: -36px;
    right: 0px;
  }

  .circles {
    width: 188px;
    left: 17px;
    top: -26px;
  }

  /*  */

  .btm-section .actions li {
    font-size: 13px;
    line-height: 20.4px;
    margin-bottom: 9px;
  }
  .btm-section ul {
    padding-left: 25px;
  }
  .btm-section .stage {
    height: 95px;
    width: 235px;
  }
  .stage h6 {
    font-size: 12px;
    line-height: 14.4px;
    margin-left: 97px;
  }
  .btm-section {
    grid-template-rows: 300px;
  }
  .aboutus {
    height: 940px;
  }
  .spin-circle {
    width: 1022px;
    height: 821px;
  }
  .spin-up {
    top: -26%;
    left: 30%;
  }
  .rooms .room:nth-child(1) {
    grid-area: karaoke;
  }
  .rooms .room:nth-child(2) {
    grid-area: private;
    margin-left: -255px;
  }
  .rooms .room:nth-child(3) {
    grid-area: playstation;
  }
  .rooms .room:nth-child(4) {
    grid-area: allThree;
  }
  .rooms .room:nth-child(5) {
    grid-area: instagram;
  }
  .rooms {
    grid-template-areas:
      'karaoke karaoke private'
      'playstation allThree instagram';
    grid-template-columns: repeat(3, 1fr);
    /* grid-template-rows: 230px 230px; */
    grid-template-rows: 250px 250px;
  }
  .rooms .room {
    height: 80%;
  }
  .rooms h3 {
    font-size: 29px;
    line-height: 32px;
  }
  .rooms .room.bg:hover h3 {
    /* font-size: 24px; */
    font-size: 33px;
    line-height: 36px;
  }
  .rooms .room.bg.sm h3 {
    font-size: 16px;
    line-height: 20px;
  }
  .rooms .room.bg.sm:hover h3 {
    /* font-size: 14.58px; */
    font-size: 17px;
    line-height: 21px;
  }
  .highlights {
    height: 1250px;
    align-items: unset;
    flex-direction: column;
  }
  .single-highlight {
    height: 250px;
    width: 100%;
    /* background-size: cover !important;
    background-position: center !important; */
  }

  .single-highlight .highlight-img {
    height: 200%;
    width: 100%;
  }

  .highlight-content {
    width: 93%;
    height: calc(100% - 55px);
  }

  .slogan-section {
    margin: 30px 0px;
  }

  .sliding-background {
    animation: slideTablet 20s linear infinite;
  }

  .fnb {
    flex-direction: column !important;
  }
  .fnb .text {
    width: 70%;
    margin: 0 auto;
  }
  .fnb .text h3,
  .fnb .text p {
    text-align: center;
  }
  .contact-form {
    flex-direction: column !important;
  }
  .contact-form .text h3 span {
    display: inline-block;
  }
  .contact-form .text h3 .last-span {
    margin-left: 12px;
  }
  .contact-form .text h3 {
    text-align: center;
  }
  .first-span {
    display: block;
  }

  .contact-form form {
    width: 50%;
    display: grid;
    grid-template-areas:
      'name phone'
      'email email';
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 50px 50px;
    place-items: center;
    margin-bottom: 25px;
  }
  .contact-form form .form-group:nth-child(1) {
    grid-area: name;
    width: 86% !important;
  }
  .contact-form form .form-group:nth-child(2) {
    grid-area: phone;
    width: 86%;
  }
  .contact-form form .form-group:nth-child(3) {
    grid-area: email;
    width: 45%;
  }
  .footer-logo {
    width: 150px;
  }
  .footerIcons a {
    font-size: 30px;
    margin: 0 1.15rem;
  }
  .footer-text h3 {
    font-size: 25px;
    line-height: 25px;
  }
  .footer-text h6 {
    font-size: 8.76px;
  }
  .footer-text {
    margin-right: 30px;
  }
  footer .copyright {
    font-size: 8px;
    margin: 15px auto;
  }
  .spin-dwn {
    bottom: -2%;
    transform: rotate(-200.06deg);
    right: 40%;
    width: 822px;
    height: 621px;
  }
}

@media screen and (max-width: 700px) {
  .intro {
    height: 410px;
  }
  .head-content {
    flex-direction: column !important;
    padding: 30px 0px;
  }
  .gif-contain {
    width: 200px;
    height: 200px;
    padding: 22px;
  }
  .intro .text h1 {
    font-size: 40px;
    line-height: 40px;
    text-align: center;
  }
  .intro .text h6 {
    font-size: 14px;
    margin-bottom: 10px;
    text-align: center;
  }
  .anmate-crcl h4 span:nth-child(1) {
    font-size: 12.07px;
    line-height: 22.08px;
  }
  .anmate-crcl h4 span {
    font-size: 8.91px;
    line-height: 12.89px;
  }
  .spin-up {
    top: -14%;
    left: 41%;
    transform: rotate(-203.06deg);
  }
  .spin-circle {
    width: 480px;
    height: 434px;
  }
  .top-section .boomLogo:hover {
    transform: scale(0.5);
  }
  .top-section .boomLogo {
    transform: scale(0.4);
  }
  .top-section {
    margin: 0px auto;
  }
  .top-section p {
    font-size: 13px;
    line-height: 18.8px;
    margin-bottom: 50px;
  }
  .games .game {
    height: 80px;
    min-width: 80px;
  }
  .games {
    width: 70%;
    margin: 0 auto 15px;
  }
  .game h6,
  .stage h6 {
    font-size: 12px;
    line-height: 14.4px;
  }
  .top-section h5 {
    font-size: 13px;
    line-height: 26px;
  }
  .sports h4 {
    font-size: 12px;
  }
  .sports h3 {
    font-size: 15.98px;
  }
  .sports {
    gap: 8px;
  }
  .btm-section {
    grid-template-rows: 240px 215px;
    grid-template-areas:
      'framePic framePic'
      'stage list';
    grid-template-columns: 1fr 1fr;
    margin-top: 30px;
  }
  .btm-section .stage {
    /* height: 95px;
        width: 235px; */
    grid-area: stage;
    width: 53%;
  }

  /*  */

  .main-container {
    width: 124px;
    height: 243px;
    margin-right: 95px;
  }
  /* .main-img {
    width: 140px;
    top: 12px;
    right: -39px;
  } */

  .avatar-container {
    height: 150px;
    width: 157px;
    top: 3rem;
  }

  /* .main-container:hover .main-img {
    transform: translate(-5px, -10px);
  }

  .main-container:hover .contained-img {
    transform: translate(-5px, -10px) !important;
  } */

  /* .contained-img {
    width: 140px;
    top: -36px;
    right: -7px;
  }

  .circles {
    width: 188px;
    left: 10px;
    top: -26px;
  } */

  /*  */

  .btm-section .actions {
    grid-area: list;
  }
  .main-container {
    grid-area: framePic;
  }
  .stage svg {
    height: 100%;
    width: 100%;
  }
  .stage h6 {
    font-size: 10px;
    line-height: 12.4px;
    margin-left: 4px;
  }
  .btm-section ul {
    padding-left: 22px;
    padding-right: 8px;
  }
  .rooms {
    margin-top: 0%;
    grid-template-rows: 230px 230px;
    margin-bottom: 12px;
  }
  .rooms .room {
    height: 70%;
  }
  .rooms h3 {
    font-size: 23px;
    line-height: 26px;
  }
  .rooms .room.bg:hover h3 {
    /* font-size: 18px; */
    font-size: 27px;
    line-height: 30px;
  }
  .rooms .room.bg.sm h3 {
    font-size: 14px;
    line-height: 17px;
  }
  .rooms .room.bg.sm:hover h3 {
    /* font-size: 11.58px;
    transform: translate(-15px, 0); */
    font-size: 15px;
    line-height: 18px;
  }
  .rooms .img-within h4 {
    line-height: 18px;
    font-size: 15px;
  }
  .attractions h1 {
    font-size: 25px;
  }
  .highlights {
    height: 950px;
  }
  .single-highlight {
    height: 190px;
  }
  .highlight-content .title {
    font-size: 14px;
    line-height: 20.4px;
  }
  .highlight-content {
    width: 91%;
  }
  .single-highlight:hover {
    flex-grow: 4;
  }
  .fnb .img-wrap img {
    width: 303px;
    height: 220px;
  }
  .contact-form form {
    width: 95%;
  }
  .contact-form {
    width: 100%;
  }
  .contact-form .text h3 {
    font-size: 38px;
    line-height: 40px;
  }
  .contact-form form .form-group:nth-child(3) {
    width: 50%;
  }
  .contact-form .form-control {
    padding: 7px 0px;
    border-bottom: 0.7px solid var(--clr-black);
    font-size: 15px;
    line-height: 16px;
  }

  .contact-form input::-webkit-input-placeholder {
    font-size: 15px;
  }

  .contact-form input:-moz-placeholder {
    /* Firefox 18- */
    font-size: 15px;
  }

  .contact-form input::-moz-placeholder {
    /* Firefox 19+ */
    font-size: 15px;
  }

  .contact-form input:-ms-input-placeholder {
    font-size: 15px;
  }
  .contact-us {
    height: 355px;
  }
  .contact-us .btn-submit svg {
    font-size: 27px;
    margin-top: -2px;
  }
  .contact-us .btn-submit {
    font-size: 12px;
    padding: 3px 15px;
  }
}

@media screen and (max-width: 600px) {
  .rooms .room:nth-child(2) {
    margin-left: -200px;
  }
}

@media screen and (max-width: 540px) {
  .rooms .room:nth-child(2) {
    margin-left: -130px;
  }
}

@media screen and (max-width: 500px) {
  header .logo {
    width: 20px;
    height: 28px;
  }
  .intro {
    height: 305px;
  }
  .head-content {
    padding: 24px 0px;
  }
  .intro .text h1 {
    font-size: 32px;
    line-height: 35.2px;
  }
  .intro .text h6 {
    font-size: 12px;
    line-height: 14.4px;
    margin-bottom: 16px;
  }
  .gif-contain {
    width: 152px;
    height: 152px;
    padding: 11px;
  }
  .anmate-crcl h4 span {
    font-size: 9.56px;
    line-height: 11.48px;
  }
  .anmate-crcl h4 span:nth-child(1) {
    font-size: 13.47px;
    line-height: 16.16px;
  }
  .top-section .boomLogo {
    transform: scale(0.3);
    margin-top: -22px;
  }
  .top-section .boomLogo:hover {
    transform: scale(0.4);
  }
  .top-section p {
    font-size: 12px;
    line-height: 14.4px;
    margin-bottom: 24px;
    margin-top: -38px;
  }
  .games .game {
    height: 70.85px;
    min-width: 70.85px;
  }

  .games {
    width: 77%;
    margin: 0 auto 24px;
  }
  .top-section h5 {
    font-size: 12px;
    line-height: 14.4px;
  }
  .sports h3 {
    font-size: 12.98px;
  }
  .sports {
    gap: 5px;
  }
  /* .framePIC {
    max-width: 194px;
  } */
  .btm-section {
    grid-template-rows: 255px 180px;
  }
  .btm-section .actions li {
    font-size: 11px;
    line-height: 16.4px;
  }
  .btm-section ul {
    padding-left: 18px;
  }
  .spin-dwn {
    bottom: 34%;
    transform: rotate(-228.06deg);
    /* right: 34%; */
    right: 50%;
  }
  .aboutus {
    height: 800px;
  }
  .rooms .room {
    height: 93%;
  }
  .rooms {
    padding: 0px 20px;
    grid-template-rows: 150px 150px;
  }
  .rooms .room:nth-child(2) {
    margin-left: -129px;
  }
  .rooms h3 {
    font-size: 21px;
    line-height: 23px;
  }
  .rooms .room.bg:hover h3 {
    /* font-size: 16px;
    transform: translate(-16px, 0); */
    font-size: 25px;
    line-height: 27px;
  }
  .rooms h3 span {
    margin: 5px 0px;
  }
  .rooms .room.bg.sm h3 {
    font-size: 12px;
    line-height: 15px;
  }
  .rooms .room.bg.sm:hover h3 {
    /* font-size: 10px;
    transform: translate(-7px, 0); */

    font-size: 13px;
    line-height: 16px;
  }
  .rooms .img-within h4 {
    line-height: 18px;
    font-size: 16px;
  }
  /* .attractions h1 {
    visibility: hidden;
  } */
  .single-highlight {
    height: 140px;
  }
  .highlights {
    height: 700px;
  }
  .single-highlight h2 {
    font-size: 16px;
    line-height: 17.6px;
  }
  .highlight-content {
    left: 16px;
    top: 16px;
    height: calc(100% - 28px);
    width: 92%;
  }
  .highlight-content .title {
    font-size: 20px;
    line-height: 20.4px;
  }
  .highlight-content p {
    font-size: 14px;
    line-height: 16.8px;
  }
  .single-highlight:hover {
    flex-grow: 60;
  }
  .highlight-content .btn-readMore {
    font-size: 14px;
  }
  .sliding-background {
    animation: slideMobile 20s linear infinite;
  }
  .fnb .text h3 {
    font-size: 18px;
    line-height: 19.8px;
  }
  .fnb .text p {
    font-size: 12px;
    font-weight: 600;
    line-height: 14.4px;
  }
  .fnb .img-wrap img {
    width: 217.36px;
    height: 177.55px;
  }
  .fnb {
    gap: 23px;
  }
  .contact-form .text h3 {
    font-size: 24px;
    line-height: 26.4px;
  }
  .contact-form .form-control {
    padding: 5.92px 0px;
    border-bottom: 0.79px solid var(--clr-black);
    font-size: 12px;
    line-height: 14.4px;
  }

  .contact-form input::-webkit-input-placeholder {
    font-size: 12px;
  }

  .contact-form input:-moz-placeholder {
    /* Firefox 18- */
    font-size: 12px;
  }

  .contact-form input::-moz-placeholder {
    /* Firefox 19+ */
    font-size: 12px;
  }

  .contact-form input:-ms-input-placeholder {
    font-size: 12px;
  }
  .contact-us .btn-submit {
    font-size: 9.03px;
    line-height: 7.24px;
    padding: 1px 12px;
  }
  .contact-us {
    height: 285px;
  }
  .footer-top {
    margin-top: 18px;
  }
  .footer-logo {
    width: 85px;
  }
  .footerIcons a {
    font-size: 11px;
    margin: 0 0.35rem;
  }
  .footerIcons a img {
    width: 5.5px;
  }
  .footer-text h3 {
    font-size: 20.34px;
    line-height: 20.34px;
  }
  .footer-text h6 {
    font-size: 6.13px;
    line-height: 9.09px;
  }
  .footer-text {
    margin-right: 5px;
  }
  footer .copyright {
    font-size: 6px;
    line-height: 5.75px;
    margin: 15px auto;
  }
}

@media screen and (max-width: 475px) {
  .intro .text h1 {
    font-size: 26px;
    line-height: 29.2px;
  }
  .intro .text h6 {
    font-size: 13px;
    line-height: 38.4px;
  }
  .gif-contain {
    width: 135px;
    height: 135px;
  }
  .games {
    width: 86%;
  }
  .games .game {
    height: 61.85px;
    min-width: 61.85px;
  }
  .btm-section .stage {
    width: 57%;
  }
  .stage h6 {
    font-size: 11px;
    line-height: 14px;
  }
  .btm-section ul {
    padding-left: 15px;
  }
  .btm-section .actions li {
    font-size: 10px;
    line-height: 14.4px;
  }
  .btm-section .actions {
    margin-left: -19px;
  }

  .highlights {
    height: 745px;
  }
  .footerIcons {
    margin-top: -16px;
  }
  .footer-logo {
    margin-top: 5px;
  }
  .footer-top {
    margin-top: 5px;
  }
  .footer-text h3 {
    font-size: 16.34px;
    line-height: 16.34px;
  }
  .footer-text {
    margin-right: 0px;
  }
  .footer-text h6 {
    font-size: 5.13px;
    line-height: 8.09px;
  }
  .contact-us {
    height: 235px;
  }
}

@media screen and (max-width: 460px) {
  .rooms {
    grid-template-rows: 130px 130px;
  }
  .rooms .room {
    height: 85%;
  }
  .rooms .room:nth-child(2) {
    margin-left: -92px;
  }
  .rooms h3 {
    font-size: 17px;
    line-height: 19px;
  }
  .rooms .room.bg:hover h3 {
    font-size: 19px;
    line-height: 21px;
  }
  .rooms .room.bg.sm h3 {
    font-size: 10.3px;
    line-height: 13.1px;
  }
  .rooms .room.bg.sm:hover h3 {
    /* font-size: 8px;
    transform: translate(-4px, 0); */
    font-size: 11.3px;
    line-height: 14.1px;
  }
  .rooms .img-within h4 {
    line-height: 16px;
    font-size: 12px;
  }
}

@media screen and (max-width: 414px) {
  .stage h6 {
    font-size: 9px;
    line-height: 12px;
  }
  .contact-form .form-control {
    padding: 5.92px 0px;
    border-bottom: 0.79px solid var(--clr-black);
    font-size: 10px;
    line-height: 14.4px;
  }

  .contact-form input::-webkit-input-placeholder {
    font-size: 10px;
  }

  .contact-form input:-moz-placeholder {
    /* Firefox 18- */
    font-size: 10px;
  }

  .contact-form input::-moz-placeholder {
    /* Firefox 19+ */
    font-size: 10px;
  }

  .contact-form input:-ms-input-placeholder {
    font-size: 10px;
  }
}

@media screen and (max-width: 370px) {
  .intro .text h6 {
    font-size: 12px;
  }
  .rooms {
    grid-template-rows: 105px 105px;
  }
  .rooms .room {
    height: 85%;
  }
  .rooms .room:nth-child(2) {
    margin-left: -92px;
  }
  .rooms h3 {
    font-size: 15px;
    line-height: 15px;
  }
  .rooms .room.bg:hover h3 {
    font-size: 17px;
    line-height: 18px;
  }
  .rooms h3 span {
    margin: 3px 0px;
  }
  .rooms .room.bg.sm h3 {
    font-size: 8px;
    line-height: 11px;
  }
  .rooms .room.bg.sm:hover h3 {
    /* font-size: 8px;
    transform: translate(-4px, 0); */
    font-size: 9px;
    line-height: 12px;
  }
  .rooms .img-within h4 {
    line-height: 12px;
    font-size: 10px;
  }
}

@media screen and (max-width: 360px) {
  .intro .text h6 {
    font-size: 11px;
  }
  .contact-form .form-control {
    font-size: 9px;
  }

  .contact-form input::-webkit-input-placeholder {
    font-size: 9px;
  }

  .contact-form input:-moz-placeholder {
    /* Firefox 18- */
    font-size: 9px;
  }

  .contact-form input::-moz-placeholder {
    /* Firefox 19+ */
    font-size: 9px;
  }

  .contact-form input:-ms-input-placeholder {
    font-size: 9px;
  }
}

@media screen and (max-width: 335px) {
  .stage h6 {
    font-size: 7px;
    line-height: 9px;
  }
}
